<template>
  <div class="container">
    <div class="tabs-nav">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="成绩汇总" name="0">
          <Summary ref="summary" />
        </el-tab-pane>
        <el-tab-pane label="社会实践" name="1">
          <Practice ref="practice" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Summary from './component/resultsSummary'
import Practice from './component/socialPractice'
export default {
  data() {
    return {
      activeName: 0,
    };
  },
  components: {Summary,Practice},
  created() {
    this.activeName = this.$route.params.activeName;
    this.handleClick();
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
      this.$nextTick(() => {
        switch (this.activeName) {
          case "0":
            this.$refs.summary.getFirst();
            break;
          case "1":
            this.$refs.practice.getFirst();
            break;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .tabs-nav {
    width: 100%;
    padding: 15px 16px 30px;
    background: #fff;
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;
  }
}
</style>
<style scoped>
.el-tabs--top >>> .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px;
}
</style>