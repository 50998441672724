<template>
  <div class="web-content">
    <Search
      :term="false"
      :classlist="classlist"
      ref="search"
      @handleQuery="handleQuery"
    />

    <el-row type="flex" justify="space-between">
      <el-col :span="6">
        <h3>社会实践</h3>
      </el-col>
      <el-col :span="18" class="btn" style="text-align: right">
        <el-button type="primary" plain
          ><download-excel
            :data="tableData"
            :fields="fields"
            name="社会实践.xls"
          >
            导出
          </download-excel></el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="tableHeaderColor"
      :cell-style="cellStyleFun"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="userName" label="学生姓名"> </el-table-column>
      <el-table-column prop="studentCode" label="学籍号" width="180">
      </el-table-column>
      <el-table-column prop="className" label="班级" width="120">
      </el-table-column>
      <el-table-column
        prop="practiceCount"
        label="社会实践次数"
        min-width="120"
      >
      </el-table-column>
      <el-table-column prop="sumCount" label="心得感悟条数" min-width="120">
      </el-table-column>
      <el-table-column
        prop="sumScore"
        label="本维度综素成绩（分）"
        min-width="180"
      >
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="handleDetail(scope.row)" type="text" size="small"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import Search from "@/components/Search";
import { getResult } from "@/api/teacher/quality/social";
export default {
  data() {
    return {
      total: 0,
      queryParams: {
        pageSize: 100,
      },
      classlist: [],
      tableData: [],
      fields: {
        学生姓名: "userName",
        学籍号: "studentCode",
        班级: "className",
        社会实践次数: "practiceCount",
        心得感悟条数: "sumCount",
        "本维度综素成绩（分）": "sumScore",
      },
      typeId: "341",
    };
  },
  components: { Search },
  methods: {
    getFirst() {
      this.$store.dispatch("GetClassList", this.typeId).then((res) => {
        this.classlist = res.data;
        this.queryParams.termName = this.$store.getters.queryForm.termName;
        this.queryParams.classId = this.$store.getters.queryForm.deptId;
        this.$refs.search.init(
          this.queryParams.termName,
          this.queryParams.classId
        );
        this.getList();
      });
    },
    getList() {
      getResult(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.size;
      });
    },
    handleQuery(data) {
      this.queryParams.classId = data.deptId;
      this.queryParams.searchStr = data.userName;
      this.getList();
    },
    handleDetail(row) {
      const params = {
        userId: row.userId,
        userName: row.userName,
        studentCode: row.studentCode,
        className: row.className,
        classId: this.queryParams.classId,
      };
      this.$router.push({
        name: "socialStudent",
        params: params,
      });
      sessionStorage["params"] = JSON.stringify(params);
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/el-tabs.scss";
@import "@/assets/scss/studentResult.scss";
</style>
<style scoped>
.el-form-item {
  margin-bottom: 12px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
</style>